<template>
  <div class="fd-w-full">
    <div class="fd-w-full fd-flex fd-items-center fd-justify-between">
      <span class="fd-block fd-text-lg fd-font-semibold fd-text-theme-2">{{
          $t("appointment.tableView")
      }}</span>
      <div class="fd-flex fd-items-center">
        <b-button @click="showCreateBlockTimeForm" class="fd-mr-3" :variant="'outline-primary'">{{
            $t("appointment.blockTimeslot")
        }}</b-button>

        <b-button @click="showCreateAppointmentForm" :variant="'primary'">
          {{ $t("appointment.addNewAppointment") }}
        </b-button>
      </div>
    </div>
    <div class="fd-w-full fd-bg-white fd-mt-6 fd-px-5 fd-py-6" style="border-radius: 6px">
      <div class="fd-grid fd-grid-cols-3 fd-gap-6 fd-mb-6">
        <div class="box">
          <div>
            <span class="fd-text-theme-11 fd-font-semibold fd-text-xl">
              {{ appointmentStats.today_appointments ? appointmentStats.today_appointments : 0 }}
            </span>
            <span class="
                fd-block fd-mt-1.5 fd-text-theme-9 fd-text-xs fd-font-normal
              ">
              {{ $t("appointment.todayAppointment") }}
            </span>
          </div>
          <span class="bg-icon first">
            <SvgLoader :name="'peopleIcon'" />
          </span>
        </div>
        <div class="box">
          <div>
            <span class="fd-text-theme-11 fd-font-semibold fd-text-xl">
              {{ appointmentStats.total_appointments ? appointmentStats.total_appointments : 0 }}
            </span>
            <span
              class="fd-block fd-mt-1.5 fd-text-theme-9 fd-text-xs fd-font-normal"
            >
              {{ $t("appointment.numberOfAppointment") }}
            </span>
          </div>
          <span class="bg-icon second">
            <SvgLoader :name="'calendarIcon'" />
          </span>
        </div>
        <div class="box">
          <div>
            <span class="fd-text-theme-11 fd-font-semibold fd-text-xl">
              {{ appointmentStats.accepted_appointments ? appointmentStats.accepted_appointments : 0 }}
            </span>
            <span class="
                fd-block fd-mt-1.5 fd-text-theme-9 fd-text-xs fd-font-normal
              ">
              {{ $t("appointment.numberOfAcceptedAppointment") }}
            </span>
          </div>
          <span class="bg-icon third">
            <SvgLoader :name="'calendarTickIcon'" />
          </span>
        </div>
      </div>
      <Table filterId="appointment-filters" :name="name" :total="total" :fields="fields" :items="appointments" :hasFilter="true" :hasSearch="true"
        :hasCheckIcon="true" :perPage="10" :isBusy="isBusy" :showList="false" @changedPage="setPage"
        @setSearch="setSearch" @showRemoveComponent="toggleDeleteAppointmentConfirmDialogue"
        @showEditComponent="showEditAppointmentForm" @viewComponent="showDetails" @applyFilter="applyFilter"
        @clearAllFilters="clearAllFilters" :hasViewIcon="true" @doneComponent="showSetAsDoneForm"
        @closeComponent="declineAppointment" @tickComponent="acceptAppointment"
      >
        <template #filters>
          <AppointmentFilter @filters="setFilters" />
        </template>
      </Table>
    </div>
    <div class="fd-w-full fd-bg-white fd-mt-6 fd-px-5 fd-py-6" style="border-radius: 6px">
      <span class="fd-text-theme-2 fd-text-sm fd-font-medium">{{
          $t("global.blocks")
      }}</span>
      <Table :fields="blockFields" :items="blockTimes" :perPage="10" :isBusy="isBusy" :showList="false"
        :show-total="false" :has-pagination="false" :hasFilter="false"
        @showRemoveComponent="toggleDeleteBlockTimeConfirmDialogue" @showEditComponent="showEditBlockTimeForm" />
    </div>

    <AppointmentEventHandler
      :appointmentId="currentAppointment ? currentAppointment.id : null"
      v-model="isAppointmentHandlerSidebarActive"
      @add-event="getData"
      @update-event="getData"
    />
    <SetAsDone :appointmentId="currentAppointment ? currentAppointment.id : null" v-model="isSetAsDoneSidebarActive" />

    <BlockTimeSidebar :blockTimeId="currentBlockTime ? currentBlockTime.id : null" v-model="isBlockTimeSidebarActive" />

    <ConfirmDialogue :id="deleteAppointmentDialogId" @remove="removeAppointment" />

    <ConfirmDialogue :id="deleteBlockTimeDialogId" @remove="removeBlockTime" />
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import Table from "@/views/components/global/Table";
import AppointmentFilter from "@/views/components/appointmentManagement/AppointmentFilter.vue";
import AppointmentEventHandler from "@/views/components/appointmentManagement/AppointmentEventHandler.vue";
import BlockTimeSidebar from "@/views/components/appointmentManagement/BlockTimeSidebar.vue";
import SetAsDone from "@/views/components/appointmentManagement/SetAsDone.vue";
import ConfirmDialogue from "@/views/components/global/ConfirmDialogue";
import { mapGetters } from "vuex";
export default {
  name: "TableView",
  components: {
    BButton,
    Table,
    AppointmentFilter,
    AppointmentEventHandler,
    BlockTimeSidebar,
    SetAsDone,
    ConfirmDialogue
  },
  data() {
    return {
      properties: [],
      loading: false,
      isAppointmentHandlerSidebarActive: false,
      isBlockTimeSidebarActive: false,
      isSetAsDoneSidebarActive: false,
      filters: "",
      search: "",
      currentAppointment: null,
      currentBlockTime: null,

      appointmentIdToDelete: null,
      deleteAppointmentDialogId: 'deleteAppointmentConfirmDialogue',

      blockTimeIdToDelete: null,
      deleteBlockTimeDialogId: 'deleteBlockTimeConfirmDialogue',
      queryString: ''
    };
  },
  created() {
    this.$store.dispatch('tableView/getAppointmentStats')
    this.getData();
  },
  watch: {
    search: {
      handler(newVal) {
        let data = {}
        if (newVal) {
          data.search = newVal
        }
        this.getData(data)
      }
    }
  },
  computed: {
    ...mapGetters({
      appointments: "tableView/appointments",
      appointmentStats: "tableView/appointmentStats",
      blockTimes: "tableView/blockTimes",
      total: "tableView/appointmentsTotal"
    }),
    fields() {
      return [
        {
          key: "ana_agent",
          label: this.$t("table.agentName"),
          thStyle: { width: "10%" },
        },
        {
          key: "rs_agent",
          label: this.$t("table.rsAgentsMember"),
          thStyle: { width: "10%" },
        },
        { key: "lead.name", label: this.$t("table.lead"), thStyle: { width: "5%" } },
        {
          key: "project.name",
          label: this.$t("table.project"),
          thStyle: { width: "5%" },
        },
        {
          key: "start_datetime",
          label: this.$t("table.appointmentDateAndTime"),
          formatter: (value, key, item) => {
            return (
              item.start_datetime + " - " + item.end_datetime.split(" ")[1]
            );
          },
          thStyle: { width: "12%" },
        },
        {
          key: "template_type",
          label: this.$t("table.template"),
          thStyle: { width: "5%" },
        },
        {
          key: "status",
          label: this.$t("table.status"),
          thStyle: { width: "5%" },
        },
        {
          key: "actions",
          label: "ACTIONS",
          class: "text-right",
          checkByStatus: true,
          thStyle: { width: "5%" },
        },
      ];
    },
    blockFields() {
      return [
        {
          key: "agent",
          label: this.$t("table.nameMember"),
          thStyle: { width: "10%" },
          formatter: (value, key, item) => {
            return item.agent.first_name + ' ' + item.agent.last_name
          }
        },
        {
          key: "start_datetime",
          label: this.$t("table.from"),
          thStyle: { width: "10%" }
        },
        {
          key: "end_datetime",
          label: this.$t("table.to"),
          thStyle: { width: "10%" }
        },
        {
          key: "created_at",
          label: this.$t("table.registeredAt"),
          thStyle: { width: "10%" },
        },
        {
          key: "actions",
          label: "ACTIONS",
          class: "text-right",
          thStyle: { width: "5%" },
        },
      ];
    },
    name() {
      return "Properties";
    },
    isBusy() {
      return this.loading;
    },
  },
  methods: {
    toggleDeleteAppointmentConfirmDialogue($id) {
      this.appointmentIdToDelete = $id;
      this.$root.$emit("bv::toggle::modal", this.deleteAppointmentDialogId);
    },
    removeAppointment($id) {
      this.$root.$emit("bv::toggle::modal", this.deleteAppointmentDialogId);
      this.$store
        .dispatch("tableView/deleteAppointment", this.appointmentIdToDelete);

    },
    toggleDeleteBlockTimeConfirmDialogue(id) {
      this.blockTimeIdToDelete = id;
      this.$root.$emit("bv::toggle::modal", this.deleteBlockTimeDialogId);

    },
    removeBlockTime() {
      this.$root.$emit("bv::toggle::modal", this.deleteBlockTimeDialogId);

      this.$store
        .dispatch("tableView/deleteBlockTime", this.blockTimeIdToDelete);
    },
    async getData(data = {}) {
      this.loading = true;
      await this.$store.dispatch("tableView/getAppointments", data);
      await this.$store.dispatch("tableView/getBlockTimes");
      this.loading = false;
    },
    showCreateAppointmentForm() {
      this.currentAppointment = null;
      this.toggleAppointmentFormSidebar();
    },
    showEditAppointmentForm(appointmentId) {
      const appointment = this.appointments.find(
        (element) => element.id == appointmentId
      );
      this.currentAppointment = appointment;
      this.toggleAppointmentFormSidebar();
    },
    showSetAsDoneForm(appointmentId) {
      const appointment = this.appointments.find(
        (element) => element.id == appointmentId
      );
      this.currentAppointment = appointment;
      this.isSetAsDoneSidebarActive = true;
    },
    declineAppointment(appointmentId) {
      this.$store.dispatch('tableView/declineAppointment', appointmentId).then(() => {
        this.getData()
      })
    },
    acceptAppointment(appointmentId) {
      this.$store.dispatch('tableView/acceptAppointment', appointmentId).then(() => {
        this.getData()
      })
    },
    showCreateBlockTimeForm() {
      this.currentBlockTime = null;
      this.toggleBlockTimeFormSidebar();
    },
    showEditBlockTimeForm(blockTimeId) {
      console.log(blockTimeId);
      const blockTime = this.blockTimes.find(
        (element) => element.id == blockTimeId
      );
      this.currentBlockTime = blockTime;
      this.toggleBlockTimeFormSidebar();
    },
    createAppointment() { },
    setPage(number) {
      let data = {
        number
      }
      if (this.search) {
        data.search = this.search
      }
      this.getData(data)
    },
    setSearch(search) {
      this.search = search;
    },
    removeProperty() { },
    editProperty() { },
    showDetails(id) {
      this.$router.push(`/appointment/${id}/details`);
     },
    applyFilter() {
      if (!this.filters) return;

      let data = {
        filters: {}
      };
      for (var key in this.filters) {
        // skip loop if the property is from prototype
        if (!this.filters.hasOwnProperty(key)) continue;

        var value = this.filters[key];
        if (
          typeof value === "object" &&
          !Array.isArray(value) &&
          value !== null
        ) {
          if (value.hasOwnProperty("id")) {
            data.filters[key] = value.id;
          } else {
            console.log("Id not found on object: " + key);
          }
        } else {
          data.filters[key] = value;
        }
      }
      if (this.search) {
        data.search = this.search
      }
      this.getData(data)
    },
    clearAllFilters() {
      let data = {}
      if (this.search) {
        data.search = this.search
      }
      this.getData(data)
    },
    setFilters(filters) {
      this.filters = filters;
    },
    toggleAppointmentFormSidebar() {
      // this.$root.$emit("bv::toggle::collapse", "appointmentForm");
      this.isAppointmentHandlerSidebarActive = true;
    },
    toggleBlockTimeFormSidebar() {
      this.isBlockTimeSidebarActive = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  background: rgba(0, 0, 0, 0.0001);
  box-shadow: 0 3.83827px 23.0296px rgba(0, 0, 0, 0.06);
  border-radius: 5.7574px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 18px;

  & .bg-icon {
    padding: 14px;
    border-radius: 100%;

    &.first {
      background-color: #dbe0f7;
    }

    &.second {
      background-color: #f7dff1;
    }

    &.third {
      background-color: #eeedfd;
    }
  }
}
</style>
