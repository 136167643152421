<template>
  <div v-if="Object.keys(appointmentOptions).length">
    <b-sidebar
      id="blockTimeSidebar"
      :visible="isBlockTimeSidebarActive"
      no-header
      bg-variant="white"
      shadow
      right
      lazy
      backdrop
      width="25%"
      :backdrop-variant="'dark'"
      @change="(val) => $emit('update:is-block-time-sidebar-active', val)"
    >
      <div
        class="fd-relative fd-flex fd-items-center fd-justify-between fd-p-3"
        style="background-color: #e9edeb"
      >
        <div class="fd-flex fd-items-center">
          <span class="fd-block fd-ml-2">Leave</span>
        </div>
        <div
          @click="$emit('update:is-block-time-sidebar-active', false)"
          class="fd-cursor-pointer"
        >
          <SvgLoader :name="'close'" />
        </div>
      </div>

      <div class="fd-p-3 fd-pb-32">
        <div class="fd-px-3 fd-py-3 fd-rounded fd-bg-theme-1 fd-mb-3">
          <div class="fd-text-base fd-text-theme-13 fd-mb-3">
            <div class="fd-font-semibold fd-mb-5">Agent</div>
            <label for="agent_select" class="fd-mb-2">Agent Name</label>
            <multiselect
              id="agent_select"
              label="name"
              select-label=""
              deselect-label=""
              v-model="inputData.agent"
              :options="appointmentOptions.internal.agents"
              :custom-label="firstNameWithLastName"
            />
          </div>
        </div>
        <div class="fd-px-3 fd-py-3 fd-rounded fd-bg-theme-1">
          <div class="fd-text-base fd-text-theme-13 fd-mb-3">
            <div class="fd-font-semibold fd-mb-5">Date & Time</div>
            <div class="fd-mb-4">
              <div class="fd-font-semibold fd-mb-2">Start</div>
              <div class="fd-grid fd-grid-cols-2 fd-gap-6">
                <div class="">
                  <label for="appointment_date" class="fd-mb-2">Date</label>
                  <flat-pickr
                    id="appointment_date"
                    v-model="inputData.start_date"
                    class="form-control"
                    :config="{
                      altInput: true,
                      altFormat: 'F j, Y',
                      dateFormat: 'Y-m-d',
                      altInputClass: 'bg-white form-control background--color--changed',
                    }"
                  />
                </div>
                <div class="">
                  <label for="appointment_start_time" class="fd-mb-2">
                    Time</label
                  >
                  <b-form-timepicker
                    v-model="inputData.start_time"
                    locale="en"
                  ></b-form-timepicker>
                </div>
              </div>
            </div>
            <div class="">
              <div class="fd-font-semibold fd-mb-2">End</div>
              <div class="fd-grid fd-grid-cols-2 fd-gap-6">
                <div class="">
                  <label for="appointment_date" class="fd-mb-2">Date</label>
                  <flat-pickr
                    id="appointment_date"
                    v-model="inputData.end_date"
                    class="form-control"
                    :config="{
                      altInput: true,
                      altFormat: 'F j, Y',
                      dateFormat: 'Y-m-d',
                      altInputClass: 'bg-white form-control background--color--changed',
                    }"
                  />
                </div>
                <div class="">
                  <label for="appointment_start_time" class="fd-mb-2">
                    Time</label
                  >
                  <b-form-timepicker
                    v-model="inputData.end_time"
                    locale="en"
                  ></b-form-timepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="fd-w-full fd-absolute fd-left-0 fd-bottom-0 fd-p-3 fd-bg-white"
        style="z-index: 99"
      >
        <SubmitButton
          @click="submit()"
          variant="primary"
          class="fd-w-full fd-mb-2"
          text="Save"
          :loading="loading"
        />
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormTimepicker,
  BSidebar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ModelListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";
import "vue-slider-component/theme/default.css";
import { mapGetters } from "vuex";
import flatPickr from "vue-flatpickr-component";
import TextEditor from "@/views/components/global/TextEditor";
import SubmitButton from "@/views/components/global/SubmitButton";
import AppointmentService from "@/http/appointment/appointmentService";

export default {
  name: "BlockTimeSidebar",
  props: {
    blockTimeId: {
      default: null,
    },
    isBlockTimeSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  model: {
    prop: "isBlockTimeSidebarActive",
    event: "update:is-block-time-sidebar-active",
  },

  components: {
    BFormInput,
    ModelListSelect,
    BFormTimepicker,
    flatPickr,
    BSidebar,
    BButton,
    BFormCheckbox,
    TextEditor,
    SubmitButton,
  },
  data() {
    return {
      blockTime: null,
     
      inputData: {
        agent: null,
        start_date: null,
        end_date: null,
      },
      loading: false,
    };
  },
  watch: {
    blockTimeId: {
      handler(newVal) {
        this.blockTime = null;
        this.resetInputData();
        if (newVal !== null) {
          AppointmentService.getBlockTimeDetails(this.blockTimeId).then(
            (response) => {
              this.blockTime = response.data.result;
              this.resetInputData();
            }
          );
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      appointmentOptions: "tableView/appointmentOptions",
      mappedInternalAgents: "tableView/mappedInternalAgents",
    }),
  },
  methods: {
    firstNameWithLastName ({ first_name, last_name }) {
      return `${first_name} ${last_name}`
    },
    resetInputData() {
      if (this.blockTime) {
        this.inputData = {
          agent: null,
          start_date: null,
          start_time: null,
          end_date: null,
          end_time: null,
        };
      } else {
        this.inputData = {
          agent: null,
          start_date: null,
          start_time: null,
          end_date: null,
          end_time: null,
        };
      }
    },

    makeToast(message, isDanger = false) {
      this.$bvToast.toast(message, {
        title: isDanger ? "Error" : "Done",
        toaster: "b-toaster-top-right",
        variant: isDanger ? "danger" : "success",
        solid: true,
      });
    },
    async submit() {
      this.loading = true;
      if (this.blockTime) await this.updateBlockTime();
      else await this.createBlockTime();
      this.loading = false;
    },
    async createBlockTime() {
      await this.$store
        .dispatch("tableView/createNewBlockTime", this.getFormattedInputs())
        .then((response) => {
          this.blockTime = response.data.result;
          this.makeToast("Block time created successfully");
          this.$emit("update:is-block-time-sidebar-active", false);
        })
        .catch((err) => {});
    },
    async updateBlockTime() {
      await this.$store
        .dispatch("tableView/updateBlockTime", {
          data: this.getFormattedInputs(),
          id: this.blockTime.id,
        })
        .then((response) => {
          this.blockTime = response.data.result;
          this.makeToast("Block time updated successfully");
          this.$emit("update:is-block-time-sidebar-active", false);
        })
        .catch((err) => {});
    },
    getFormattedInputs() {
      return {
        agent_id: this.inputData.agent.id,
        start_datetime: this.inputData.start_date + " " + this.inputData.start_time,
        end_datetime: this.inputData.end_date + " " + this.inputData.end_time,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.custom-dot {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #e1cdae;
  transition: all 0.3s;
  position: absolute;
  top: -2px;
  cursor: pointer;

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  }

  &:hover {
    transform: rotateZ(45deg);
  }
}

::v-deep .vue-slider-rail {
  background-color: #ededed !important;
}
.direct_to_lead {
  padding-left: 1.1rem !important;
}

#agent_block_time {
  height: 10rem;
  #agent_block_time_hours {
    background: $primary;
    height: 2rem;
    .bolck_time_item {
      border-right: 1px solid #e9edeb;
      height: 6.5rem;
      margin-top: 0.4rem;
      width: 50%;
    }
  }
}

.blockTimeDiv {
  background: #bec9c2;
  border: 1px solid transparent;
  border-radius: 6px;
  height: 3rem;
  top: 4rem;
}

::v-deep .background--color--changed.form-control:disabled, ::v-deep .background--color--changed.form-control[readonly] {
  background-color: #ffffff !important;
}
</style>
