<template>
  <div class="description fd-relative">
    <div class="fd-absolute fd-right-4 fd-bottom-4 fd-flex fd-items-center" style="z-index: 9">
      <button
        class="fd-mr-2"
        :class="[editor.isActive('bold') ? 'active' : '']"
        @click.prevent="toggleBold"
      >
        <SvgLoader :name="'boldIcon'" />
      </button>

      <button
        class="fd-mr-2"
        :class="[editor.isActive('italic') ? 'active' : '']"
        @click.prevent="toggleItalic"
      >
        <SvgLoader :name="'italicIcon'" />
      </button>

      <button
        class="fd-mr-2"
        :class="[editor.isActive('underline') ? 'active' : '']"
        @click.prevent="toggleUnderline"
      >
        <SvgLoader :name="'underlineIcon'" />
      </button>

      <button
        class="fd-mr-2"
        :class="[editor.isActive('textStyle') ? 'active' : '']"
        @click.prevent="changeFontFamily"
      >
        <SvgLoader :name="'typoIcon'" />
      </button>

      <button
        class="fd-mr-2"
        :class="[editor.isActive({textAlign: this.textAlign}) ? 'active' : '']"
        @click.prevent="changeTextAlign"
      >
        <SvgLoader :name="'alignment'" />
      </button>

      <button
        class="fd-mr-2"
        :class="[editor.isActive('textStyle') ? 'active' : '']"
        @click.prevent="showLinkPopup"
      >
        <SvgLoader :name="'linkIcon'" />
      </button>
    </div>
    <editor-content :editor="editor" />
    <b-modal id="set-link-modal" centered hide-footer>
      <span class="fd-block fd-mb-2 fd-font-demibold">Please write the url</span>
      <b-form-input v-model="link" placeholder="www.example.com"></b-form-input>
      <div class="fd-w-full fd-flex fd-items-center fd-mt-8">
        <b-button @click="setLink" class="fd-w-full fd-mr-3" :variant="'primary'">Add</b-button>
        <b-button @click="hideLinkPopup" class="fd-w-full" :variant="'outline-primary'">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import { BModal, BFormInput, BButton } from 'bootstrap-vue'
import StarterKit from '@tiptap/starter-kit'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import FontFamily from '@tiptap/extension-font-family'
import TextStyle from '@tiptap/extension-text-style'

export default {
  name: 'TextEditor',
  components: {
    EditorContent,
    BModal,
    BFormInput,
    BButton
  },

  props: {
    value: {
      type: String
    }
  },

  data() {
    return {
      editor: null,
      textAlign: 'left',
      font: 'urbanist',
      link: ''
    }
  },

  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value
      if (isSame) { return; }
      this.editor.commands.setContent(value, false)
    },
    textAlign: {
      handler() {
        this.editor.chain().focus().setTextAlign(this.textAlign).run()
      }
    },
    font: {
      handler() {
        this.editor.chain().focus().setFontFamily(this.font).run()
      }
    }
  },

  created() {
    Link.configure({
      linkOnPaste: false,
      openOnClick: false,
    })

    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Bold,
        Italic,
        Link.configure({
          openOnClick: false,
        }),
        Underline,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        FontFamily,
        TextStyle
      ],
      onUpdate: () => {
        this.$emit('update:value', this.editor.getHTML())
      },
    })
  },

  methods: {
    showLinkPopup() {
      this.link = this.editor.getAttributes('link').href
      this.$root.$emit("bv::show::modal", "set-link-modal");
    },
    hideLinkPopup() {
      this.$root.$emit("bv::hide::modal", "set-link-modal");
    },
    toggleBold() {
      this.editor.chain().focus().toggleBold().run()
    },
    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run()
    },
    toggleUnderline() {
      this.editor.chain().focus().toggleUnderline().run()
    },
    changeFontFamily() {
      if (this.font === 'urbanist') { return this.font = 'arial' }
      if (this.font === 'arial') { return this.font = 'tahoma' }
      if (this.font === 'tahoma') { return this.font = 'Inter' }
      this.font = 'urbanist'
    },
    changeTextAlign() {
      if (this.textAlign === 'left') { return this.textAlign = 'center' }
      if (this.textAlign === 'center') { return this.textAlign = 'right' }
      this.textAlign = 'left'
    },
    setLink() {
      if (!this.link) {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
        this.hideLinkPopup()
        return;
      }
      this.editor.chain().focus().extendMarkRange('link').setLink({ href: this.link }).run()
      this.hideLinkPopup()
    }
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss" scoped>
::v-deep .ProseMirror {
  border: 1px solid #E6E7E6;
  border-radius: 6px;
  padding: 10px 15px;
  height: 300px;
  overflow: scroll;

  & a {
    color: #1ab7ea;
    text-decoration: underline;
  }
}

.description {
  button {
    ::v-deep svg {
      path {
        fill: #6E6B7B;
      }
    }
    &.active {
      ::v-deep svg {
        path {
          fill: #2f2e32;
        }
      }
    }
  }
}
</style>