<template>
  <div>
    <b-sidebar
      id="setAsDoneSidebar"
      :visible="isSetAsDoneSidebarActive"
      no-header
      bg-variant="white"
      shadow
      right
      lazy
      backdrop
      :backdrop-variant="'dark'"
      width="25%"
      @change="(val) => $emit('update:is-set-as-done-sidebar-active', val)"
    >
      <div
        class="fd-relative fd-flex fd-items-center fd-justify-between fd-p-3"
        style="background-color: #e9edeb"
      >
        <div class="fd-flex fd-items-center">
          <span class="fd-block fd-ml-2">Set as done</span>
        </div>
        <div
          @click="$emit('update:is-set-as-done-sidebar-active', false)"
          class="fd-cursor-pointer"
        >
          <SvgLoader :name="'close'" />
        </div>
      </div>

      <div>
        <div class="fd-p-3 fd-pb-32">
          <div
            class="
              fd-text-base
              fd-text-theme-13
              fd-mb-4
              fd-px-3
              fd-py-3
              fd-rounded
              fd-bg-theme-1
            "
          >
            <div class="">
              <div class="fd-font-semibold fd-mb-5">Lead Information</div>
              <div class="fd-mb-3">
                <label for="lead_name" class="fd-mb-2">Name</label>
                <b-form-input
                  id="lead_name"
                  v-model="inputData.name"
                  type="text"
                  debounce="500"
                  class=""
                ></b-form-input>
              </div>
              <div class="fd-mb-3">
                <label for="lead_email" class="fd-mb-2">Email</label>
                <b-form-input
                  id="lead_email"
                  v-model="inputData.email"
                  type="email"
                  debounce="500"
                  class=""
                ></b-form-input>
              </div>
              <div class="fd-mb-3">
                <label for="lead_phone" class="fd-mb-2">Phone</label>
                <b-form-input
                  id="lead_phone"
                  v-model="inputData.phone"
                  type="tel"
                  debounce="500"
                  class=""
                ></b-form-input>
              </div>
              <div class="fd-mb-3">
                <label for="lead_id" class="fd-mb-2">ID</label>
                <b-form-input
                  id="lead_id"
                  v-model="inputData.id"
                  type="text"
                  debounce="500"
                  class=""
                ></b-form-input>
              </div>
              <div class="fd-mb-3">
                <label for="lead_template" class="fd-mb-2">Template</label>
                <multiselect
                  id="lead_template"
                  select-label=""
                  deselect-label=""
                  v-model="inputData.template"
                  :options="[]"
                  :custom-label="(opt) => 'mappedTemplates[opt]'"
                />
              </div>
              <div class="fd-mb-3">
                <label for="lead_price" class="fd-mb-2">Price</label>
                <vue-slider
                  id="lead_price"
                  class=""
                  v-model="inputData.price"
                  :process-style="{ backgroundColor: '#284A33', height: '5px' }"
                  v-bind="sliderOptions"
                  :dotStyle="{ width: '18px', height: '18px' }"
                  :tooltip="'none'"
                  :use-keyboard="false"
                  :lazy="true"
                >
                  <template v-slot:dot="{}">
                    <div :class="['custom-dot']"></div>
                  </template>
                </vue-slider>
                <div class="fd-mt-2">
                  <span class="fd-text-xs" style="color: #626687">{{
                    inputData.price[0] + " - " + inputData.price[1]
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="fd-w-full fd-absolute fd-left-0 fd-bottom-0 fd-p-3 fd-bg-white"
        style="z-index: 99"
      >
        <SubmitButton
          @click="submit()"
          variant="primary"
          class="fd-w-full fd-mb-2"
          text="Set as done"
          :loading="loading"
        />
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormTimepicker,
  BSidebar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ModelListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { mapGetters } from "vuex";
import flatPickr from "vue-flatpickr-component";
import TextEditor from "@/views/components/global/TextEditor";
import SubmitButton from "@/views/components/global/SubmitButton";
import AppointmentService from "@/http/appointment/appointmentService";

export default {
  name: "SetAsDone",
  props: {
    appointmentId: {
      default: null,
    },
    isSetAsDoneSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  model: {
    prop: "isSetAsDoneSidebarActive",
    event: "update:is-set-as-done-sidebar-active",
  },

  components: {
    BFormInput,
    ModelListSelect,
    VueSlider,
    BFormTimepicker,
    flatPickr,
    BSidebar,
    BButton,
    BFormCheckbox,
    TextEditor,
    SubmitButton,
  },
  data() {
    return {
      appointment: null,
      sliderOptions: {
        minRange: 1,
        min: 1,
        max: 500000,
        interval: 1,
      },
      inputData: {
        id: null,
        name: null,
        email: null,
        phone: null,
        template: null,
        price: [100000, 400000],
      },
      loading: false,
    };
  },
  created() {},
  watch: {
    appointmentId: {
      handler(newVal) {
        this.appointment = null;
        this.resetInputData();
        if (newVal !== null) {
          AppointmentService.getAppointmentDetails(this.appointmentId)
            .then((response) => {
              this.appointment = response.data.result;
              this.resetInputData();
            })
            .catch((err) => {
              this.makeToast("Unable to get appointment data", true);
              this.$emit("update:is-set-as-done-sidebar-active", false);
            });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  methods: {
    resetInputData() {
      if (this.appointment) {
        this.inputData = {
          id: null,
          name: null,
          email: "alireza@gmail.com",
          phone: null,
          template: null,
          price: [100000, 400000],
        };
      } else {
        this.inputData = {
          id: null,
          name: null,
          email: null,
          phone: null,
          template: null,
          price: [100000, 400000],
        };
      }
    },

    makeToast(message, isDanger = false) {
      this.$bvToast.toast(message, {
        title: isDanger ? "Error" : "Done",
        toaster: "b-toaster-top-right",
        variant: isDanger ? "danger" : "success",
        solid: true,
      });
    },
    async submit() {
      this.loading = true;
      await this.$store
        .dispatch("tableView/setAsDone", {
          data: this.inputData,
          id: this.appointment.id,
        })
        .then((response) => {
          this.makeToast("Appointment updated successfully");
          this.$emit("update:is-set-as-done-sidebar-active", false);
        })
        .catch((err) => {});
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.custom-dot {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #e1cdae;
  transition: all 0.3s;
  position: absolute;
  top: -2px;
  cursor: pointer;

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  }

  &:hover {
    transform: rotateZ(45deg);
  }
}

::v-deep .vue-slider-rail {
  background-color: #ededed !important;
}
.direct_to_lead {
  padding-left: 1.1rem !important;
}

#agent_block_time {
  height: 10rem;
  #agent_block_time_hours {
    background: $primary;
    height: 2rem;
    .bolck_time_item {
      border-right: 1px solid #e9edeb;
      height: 6.5rem;
      margin-top: 0.4rem;
      width: 50%;
    }
  }
}

.blockTimeDiv {
  background: #bec9c2;
  border: 1px solid transparent;
  border-radius: 6px;
  height: 3rem;
  top: 4rem;
}
</style>
