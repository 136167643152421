<template>
  <div>
    <div v-if="appointmentFilters" class="fd-p-4 fd-pb-32">
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="agent_select" class="fd-mb-2">Agent Name</label>
        <multiselect
          id="agent_select"
          :custom-label="firstNameWithLastName"
          select-label=""
          deselect-label=""
          v-model="filters.agent"
          :options="appointmentFilters.ana_agents"
          track-by="id"
        />
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="rs_select" class="fd-mb-2">RS</label>
        <multiselect
          id="rs_select"
          v-model="filters.rs"
          label="name"
          select-label=""
          deselect-label=""
          @input="getRsAgents"
          track-by="id"
          :options="appointmentFilters.rs"
        />
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="rs_agent_select" class="fd-mb-2">RS Agent</label>
        <multiselect
          :disabled="!filters.rs"
          id="rs_agent_select"
          select-label=""
          deselect-label=""
          :custom-label="firstNameWithLastName"
          v-model="filters.rs_agent"
          track-by="id"
          :options="rsAgents"
        />
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="lead_select" class="fd-mb-2">Lead</label>
        <multiselect
          id="lead_select"
          v-model="filters.lead"
          select-label=""
          deselect-label=""
          track-by="id"
          :custom-label="firstNameWithLastName"
          :options="appointmentFilters.lead"
        />
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="project_select" class="fd-mb-2">Project</label>
        <multiselect
          id="project_select"
          v-model="filters.project"
          select-label=""
          deselect-label=""
          label="name"
          @input="getProperties"
          :options="appointmentFilters.project"
        />
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="project_select" class="fd-mb-2">Appointment Date</label>
        <flat-pickr
          v-model="filters.date"
          class="form-control"
          :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d',}"
        />
     
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="project_select" class="fd-mb-2">Appointment (Start Time)</label>
        <!-- <input class="form-control" type="time" name=""  v-model="filters.start_time" id="project_select"> -->
        <b-form-timepicker v-model="filters.start_time" locale="en"></b-form-timepicker>
     
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="project_select" class="fd-mb-2">Appointment (End Time)</label>
        <!-- <input class="form-control" type="time" name=""  v-model="filters.start_time" id="project_select"> -->
        <b-form-timepicker v-model="filters.end_time" locale="en"></b-form-timepicker>
     
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="template_select" class="fd-mb-2">Template</label>
        <multiselect
          id="template_select"
          v-model="filters.template"
          select-label=""
          deselect-label=""
          label="name"
          track-by="id"
          :options="properties"
        />
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="status_select" class="fd-mb-2">Status</label>
        <multiselect
          id="status_select"
          v-model="filters.status"
          select-label=""
          deselect-label=""
          :options="appointmentFilters.status"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BFormInput, BFormTimepicker } from "bootstrap-vue";
import { ModelListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { mapGetters } from "vuex";
import flatPickr from 'vue-flatpickr-component'


export default {
  name: "appointmentFilter",
  components: { BFormInput, ModelListSelect, VueSlider , BFormTimepicker  ,flatPickr},
  data() {
    return {
      filters: { 
        agent: null,
        rs: null,
        rs_agent: null,
        lead: null,
        project: null,
        template: null,
        status: null,
        date: null,
        start_time: null,
        end_time: null,
        
        
      },
      rsAgentsList: [],
      propertiesList: []
    };
  },
  created() {
    this.$store.dispatch("tableView/getFilters");
  },
  watch: {
    filters: {
      handler(newVal) {
     

        if(!newVal.rs)
          this.filters.rs_agent = null;
          
        let filters = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) {
            filters[key] = this.filters[key];
          }
        });
        return this.$emit("filters", filters);
      },
      deep: true,
    },
  },
  computed: {
    appointmentFilters() {
      //  return this.$store.getters["tableView/filters"]
      const filters = this.$store.getters["tableView/filters"];
      // console.log(filters.length == 0);
      if (filters.length === 0) return false;
      return filters;
    },
    rsAgents() {
      return this.rsAgentsList
    },
    properties() {
      return this.propertiesList
    }
  },
  methods: {
    applyFilter() {},
    clearAllFilters() {},
    firstNameWithLastName ({ first_name, last_name }) {
      return `${first_name} ${last_name}`
    },
    getRsAgents(val, id) {
      if (!val) {
        this.rsAgents = []
        return
      }
      this.appointmentFilters.rs.forEach(item => {
        if (item.id === val.id) {
          this.rsAgentsList = item.agents
        }
      })
    },
    getProperties(val, id) {
      if (!val) {
        this.propertiesList = []
        return
      }
      this.appointmentFilters.project.forEach(item => {
        if (item.id === val.id) {
          this.propertiesList = item.properties
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-dot {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #e1cdae;
  transition: all 0.3s;
  position: absolute;
  top: -2px;
  cursor: pointer;

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  }

  &:hover {
    transform: rotateZ(45deg);
  }
}
::v-deep .vue-slider-rail {
  background-color: #ededed !important;
}
</style>
